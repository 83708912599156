import React, { Component } from "react";
import { Link as GatsbyLink } from "gatsby";
import "./Footer.css";
import config from "../../../data/SiteConfig";
import FooterData from "../../../data/AdminHeaderItems.json";
import ServicesData from "../../../data/AdminServices.json";
import ContactData from "../../../data/AdminContact.json";
import LogoWhite from "../../../static/logos/logo-white.svg";
import LinkedinIcon from "../../../static/icons/linkedin.svg";
import TwitterIcon from "../../../static/icons/twitter.svg";

class Footer extends Component {
  render() {
    const title = config.siteTitle;
    const Link = GatsbyLink;

    return (
      <footer className="footer">
        <div className="footer-grid-logo-section">
          <div className="footer-logo-wrapper">
            <Link to="/" title="Ana Sayfa">
              <img className="logoSectionMobile" src={LogoWhite} alt="Logo" />
            </Link>
            <div className="footer-social-icons">
              {ContactData.linkedin ? (
                <a href={ContactData.linkedin} title="Linkedin">
                  <img src={LinkedinIcon} alt="Linkedin" />
                </a>
              ) : null}
              {ContactData.twitter ? (
                <a href={ContactData.twitter} title="Twitter">
                  <img src={TwitterIcon} alt="Twitter" />
                </a>
              ) : null}
            </div>
          </div>
        </div>
        <div className="footer-grid">
          <ul>
            {ServicesData.items.map((data, index) => {
              return <li key={`footer_services_${index}`}>{data.title}</li>;
            })}
          </ul>
          <ul>
            {ServicesData.otherItems.map((data, index) => {
              return <li key={`footer_services_${index}`}>{data.title}</li>;
            })}
          </ul>
          <ul>
            {FooterData.menuItems.map((data, index) => {
              return (
                <li key={`footer_item_${index}`}>
                  <Link to={data.link} title={data.title}>
                    {data.title}
                  </Link>
                </li>
              );
            })}
          </ul>
          <ul>
            <li>
              <a href="mailto:info@buyukyilmaz.av.tr" title="E-Posta Gönder">
                info@buyukyilmaz.av.tr
              </a>
            </li>
            <li>
              <a href="tel:+902128904190" title="Telefon">
                0212 890 41 90
              </a>
            </li>
            <li>
              Çobançeşme E-5 Yan Yol Cad. Nef Ataköy 22 <br></br> 
              A Blok No: 73
              Bakırköy, İstanbul
            </li>
          </ul>
        </div>
        <div className="footer-copy">
          {title}
          <br />
          Tüm Hakkı Saklıdır <br />© {new Date().getFullYear()}
        </div>
      </footer>
    );
  }
}

export default Footer;
