import React from "react";
import { Helmet } from "react-helmet";
import config from "../../data/SiteConfig";
import "./index.css";
import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"

export default class MainLayout extends React.Component {
  render() {
    const { children } = this.props;
    return (
      <div className="">
        <Helmet>
          <meta name="description" content={config.siteDescription} />
          <html lang="tr" />
        </Helmet>
        <Header/>
        {children}
        <Footer/>
      </div>
    );
  }
}
