import React, { Component } from "react";
import { Link } from "gatsby";
import "./Header.css";
import { slide as Menu } from "react-burger-menu";
import LogoBlack from "../../../static/logos/logo-black.svg";
import LogoWhite from "../../../static/logos/logo-white.svg";
import HeaderData from "../../../data/AdminHeaderItems.json";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTop: true,
    };
    this.onScroll = this.onScroll.bind(this);
  }
  componentDidMount() {
    document.addEventListener("scroll", () => {
      const isTop = window.scrollY < 800;
      if (isTop !== this.state.isTop) {
        this.onScroll(isTop);
      }
    });
  }

  onScroll(isTop) {
    this.setState({ isTop });
  }

  showSettings(event) {
    event.preventDefault();
  }
  render() {
    const menuTitle = HeaderData.menuItems;
    return (
      <>
        <header className="header">
          <div className="container">
            <nav className="navigation">
              <Link to="/" title="Ana Sayfa">
                <img
                  className="logoSection"
                  src={this.state.isTop ? LogoWhite : LogoBlack}
                  alt="Logo"
                />
              </Link>
              <div className="menuSection">
                <ul>
                  {menuTitle.map((data, index) => {
                    return (
                      <li key={`header_item_${index}`}>
                        <Link
                          to={data.link}
                          className="menuItem"
                          style={{
                            color: `${
                              this.state.isTop ? "white" : "var(--black)"
                            }`,
                          }}
                        >
                          {data.title}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </nav>
          </div>
        </header>
        <Menu>
          <Link to="/" title="Ana Sayfa">
            <img
              className="logoSectionMobile"
              src={this.state.isTop ? LogoWhite : LogoBlack}
              alt="Logo"
            />
          </Link>
          {menuTitle.map((data, index) => {
            return (
              <Link
                key={`mobile_item_${index}`}
                to={data.link}
                className="menuItem"
              >
                {data.title}
              </Link>
            );
          })}
        </Menu>
      </>
    );
  }
}

export default Header;
